import InputCardInfoForm from '../../../components/Forms/Payment';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { ICardElement } from '../../../components/Forms/Payment';
import Footer from '../../../components/common/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import Amount from '../Amount';
import Email from '../Email';
import OrderSummary from '../OrderSummary';
import { ensure } from '../../../utility/ensure';
import Button from '../../../components/common/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
	TCheckout,
	TCheckoutResponse,
	TErrorResponse,
	checkOutSchema,
} from '../../../services/CheckOut/schema';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { addCheckOut, confirmPayment } from '../../../services/CheckOut';
import { useAtom } from 'jotai';
import {
	receiptAmountAtom,
	receiptCardBrand,
	receiptcardLastFour,
	receiptemail,
	receiptPaymentDate,
	receiptTransactionNumber,
} from '../../../store/receiptAtom';
import { showFailedAlert, showSuccessAlert } from '../../../utility/alerts';
import { useSearchParams } from 'react-router-dom';

const CheckOutContent = () => {
	const [params] = useSearchParams();
	const parsedPaymentIntent = params.get('payment_intent');

	const [isProcess, setIsProcess] = useState<boolean>(false);
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();

	const [, setReceiptAmount] = useAtom(receiptAmountAtom);
	const [, setReceiptCardBrand] = useAtom(receiptCardBrand);
	const [, setReceiptLastFour] = useAtom(receiptcardLastFour);
	const [, setReceiptEmail] = useAtom(receiptemail);
	const [, setReceiptPaymentDate] = useAtom(receiptPaymentDate);
	const [, setReceiptTransactionNumber] = useAtom(receiptTransactionNumber);

	const [, setCardBrand] = useState('');

	const [, setPaymentID] = useState<string | undefined>();

	const [cardNumberState, setCardNumberState] = useState<ICardElement>({
		error: false,
	});
	const [cardExpiryState, setCardExpiryState] = useState<ICardElement>({
		error: false,
	});
	const [cardCvcState, setCardCvcState] = useState<ICardElement>({
		error: false,
	});

	const { control, handleSubmit, setValue, reset } = useForm<TCheckout>({
		mode: 'onBlur',
		resolver: zodResolver(checkOutSchema),
	});

	const { mutate: checkOutMU } = useMutation<
		TCheckoutResponse,
		AxiosError,
		TCheckout
	>((data) => addCheckOut(data), {
		onSuccess: (data) => {
			if (data.authUrl) {
				handleNextAction(data.authUrl)
			} else {
				setReceiptAmount(data.amount);
				setReceiptCardBrand(data.cardBrand);
				setReceiptLastFour(data.cardLastFour);
				setReceiptEmail(data.email);
				setReceiptPaymentDate(data.paymentDate);
				setReceiptTransactionNumber(data.transactionNumber);
				setIsProcess(false);
				showSuccessAlert('Your payment was successful!');
				navigate('/success');
			}
		},
		onError: (error) => {
			const err: TErrorResponse = error.response?.data as TErrorResponse;
			const parsedError = JSON.parse(err.message);

			showFailedAlert(parsedError.code === 'expired_card' ? 'Your card expiry date is incorrect.' : parsedError.message);
			setIsProcess(false);
		},
	});

	const { mutate: confirmMU } = useMutation<
		TCheckoutResponse,
		AxiosError,
		string
	>((data) => confirmPayment(data), {
		onSuccess: (data) => {
			setReceiptAmount(data.amount);
			setReceiptCardBrand(data.cardBrand);
			setReceiptLastFour(data.cardLastFour);
			setReceiptEmail(data.email);
			setReceiptPaymentDate(data.paymentDate);
			setReceiptTransactionNumber(data.transactionNumber);
			setIsProcess(false);
			showSuccessAlert('Your payment was successful!');
			navigate('/success');
		},
		onError: (error) => {
			const err: TErrorResponse = error.response?.data as TErrorResponse;

			showFailedAlert(err.message);
			setIsProcess(false);
		},
	}); 

	const handleNextAction = (url: string) => {
		window.location.href = url
	}

	useEffect(() => {
		setValue('cardNumberComplete', cardNumberState.complete ? 1 : 0, {
			shouldValidate: cardNumberState.complete,
		});
	}, [cardNumberState]);

	useEffect(() => {
		setValue('cardExpiryComplete', cardExpiryState.complete ? 1 : 0, {
			shouldValidate: cardExpiryState.complete,
		});
	}, [cardExpiryState]);

	useEffect(() => {
		setValue('cardCvcComplete', cardCvcState.complete ? 1 : 0, {
			shouldValidate: cardCvcState.complete,
		});
	}, [cardCvcState]);

	const onSubmit = async (data: TCheckout) => {
		const errorMessage = 'This value was promised to be there.';

		const cardNumber = ensure(elements, errorMessage).getElement(
			CardNumberElement
		);

		if (!cardNumberState?.complete) {
			setCardNumberState({
				error: true,
				errorMessage: 'required',
				complete: false,
			});
		}

		if (!cardExpiryState?.complete) {
			setCardExpiryState({
				error: true,
				errorMessage: 'required',
				complete: false,
			});
		}

		if (!cardCvcState?.complete) {
			setCardCvcState({
				error: true,
				errorMessage: 'required',
				complete: false,
			});
		}

		if (
			cardNumberState?.complete &&
			cardExpiryState?.complete &&
			cardCvcState?.complete &&
			stripe
		) {
			ensure(stripe, errorMessage)
				.createPaymentMethod({
					type: 'card',
					card: ensure(cardNumber, errorMessage),
				})
				.then((result) => {
					if (result.error) {
						showFailedAlert(result.error.message);
						setIsProcess(false);
					} else {
						// Handle the success case
						const { paymentMethod } = result;
						setPaymentID(paymentMethod?.id);
						setIsProcess(true);

						if (paymentMethod?.id) {
							const params: TCheckout = {
								amount: data.amount,
								name: data.name,
								email: data.email,
								purpose: data.purpose,
								paymentMethodId: paymentMethod?.id,
								cardNumberComplete: cardNumberState.complete
									? 1
									: 0,
								cardExpiryComplete: cardExpiryState.complete
									? 1
									: 0,
								cardCvcComplete: cardCvcState.complete ? 1 : 0,
							};
							checkOutMU(params);
						}
					}
				})
				.catch((error) => {
					showFailedAlert(error.message);
					setIsProcess(false);
				});
		} else {
			console.log('Missing required fields.');
		}
	};

	useEffect(() => {
		if (parsedPaymentIntent) confirmMU(parsedPaymentIntent)
	}, [parsedPaymentIntent])

	useEffect(() => {
		const handlePopState = () => {
			reset();
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	window.addEventListener("pageshow", () => {
		const errorMessage = 'This value was promised to be there.';

		const cardNumber = ensure(elements, errorMessage).getElement(
			CardNumberElement
		);

		const cardExipry = ensure(elements, errorMessage).getElement(
			CardExpiryElement
		);

		const cardCvc = ensure(elements, errorMessage).getElement(
			CardCvcElement
		);

		cardNumber?.clear();
		cardExipry?.clear();
		cardCvc?.clear();
		reset({
			name: '',
			email: '',
		})
	});

	return (
		<div className='flex flex-col items-between w-full h-screen bg-white'>
			<div className='grow w-full bg-white'>
				<form
					className='flex flex-row justify-center items-center w-full h-full max-md:flex-col max-md:gap-4 max-sm:flex-col max-sm:gap-4'
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className='flex flex-1 justify-center items-center w-full h-full bg-[#F8F8F8]'>
						<div className='relative flex flex-col justify-center items-center w-full h-full max-w-[40rem] pl-24 pr-16 pt-4 pb-4 gap-8 lg:pl-24 lg:pr-16 md:pr-6 md:pl-6 max-md:pl-6 max-md:pr-6 max-sm:pl-6 max-sm:pr-6'>
							<div className='w-full h-fit  max-w-xl'>
								<div className='flex flex-row items-center mt-auto'>
									<div
										className='flex flex-row w-fit cursor-pointer mt-5'
										onClick={() => navigate(-1)}
									>
										<FontAwesomeIcon
											className='w-5 h-5'
											icon={faArrowLeftLong}
											style={{ color: '#000000    ' }}
										/>
										<p className='text-[11px] text-[#707070] ml-2 mt-[1px]'>
											Back
										</p>
									</div>

									<div className='w-full pr-12 text-center text-[18px] text-[#4B4747] tracking-tight mt-4 font-semibold'>
										Check out
									</div>
								</div>
							</div>

							<div className='flex flex-col w-full h-fit gap-2 max-w-xl'>
								<div className='flex justify-center items-center w-full h-fit border rounded-md bg-white'>
									<Amount control={control} />
								</div>

								<div className='flex justify-center items-center w-full h-fit border rounded-md bg-white'>
									<Email control={control} />
								</div>

								<div className='flex justify-center items-center w-full h-fit border rounded-md bg-white'>
									<InputCardInfoForm
										control={control}
										cardNumberState={cardNumberState}
										cardExpiryState={cardExpiryState}
										cardCvcState={cardCvcState}
										setCardNumberState={setCardNumberState}
										setCardExpiryState={setCardExpiryState}
										setCardCvcState={setCardCvcState}
										setCardBrand={setCardBrand}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='flex-1 flex-col w-full h-full flex justify-center items-center max-md:pb-24 max-sm:pb-24'>
						<div className="flex flex-col justify-center items-center w-full h-full max-w-[40rem] pl-24 pr-16 pt-4 pb-4 gap-8 lg:pl-24 lg:pr-16 md:pr-6 md:pl-6 max-md:pl-6 max-md:pr-6 max-sm:pl-6 max-sm:pr-6">
							<OrderSummary />
							<div className='w-full mt-12'>
								<Button
									disabled={isProcess ? true : false}
									type='submit'
									className='w-full p-2 pl-4 pr-4 rounded-sm'
									variant='green'
								>
									Proceed Checkout
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>

			<div className='mt-auto bottom-0 z-10'>
				<Footer />
			</div>
		</div>
	);
};

export default CheckOutContent;
