interface AmountFormatterProps {
	amount: string;
  }

  interface AmountFormatterSuccessProps {
	amount: number;
  }
  
  export const AmountFormatter = (props: AmountFormatterProps) => {
	// Remove any non-numeric characters (like commas) from the input amount
	const numericAmount = parseFloat(props.amount.replace(/[^0-9.-]+/g, ''));
  
	// Check if the numericAmount is valid (not NaN or undefined)
	if (isNaN(numericAmount) || typeof numericAmount !== 'number') {
	  return <span>$0.00</span>;
	}
  
	const formattedAmount = numericAmount.toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	  minimumFractionDigits: 2,
	});
  
	return <span>{formattedAmount}</span>;
  };


  export const AmountFormatterSuccess = (props: AmountFormatterSuccessProps) => {
	const formattedAmount = props.amount.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	});

	return <span>{formattedAmount}</span>;
};
 
  
  
  

export const classNames = (...classes: string[]): string => {
	return classes.filter(Boolean).join(' ');
};

export const formatDate = (timestamp : any) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    return formattedDate;
  }
  
  
  