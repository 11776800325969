/* Logo */
import Hooli from '../assets/hooli.svg';
import MPH from '../assets/mph.svg'
import Check from '../assets/check.png';

/* Credit Card Logo */
import Visa from '../assets/cc_logo/visa.png';
import MasterCard from '../assets/cc_logo/mastercard.png';
import Amex from '../assets/cc_logo/amex.png';
import Discover from '../assets/cc_logo/discover.png';
import Diners from '../assets/cc_logo/diners.png';
import JCB from '../assets/cc_logo/jcb.png';
import UnionPay from '../assets/cc_logo/unionpay.png';

export const IMAGES = {
	//Logo
	Hooli,
	MPH,
	Check,

	//CC Logo
	Visa,
	MasterCard,
	Amex,
	Discover,
	Diners,
	JCB,
	UnionPay,
};
