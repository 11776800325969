import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '@fortawesome/fontawesome-free/css/all.min.css';

export const showSuccessAlert = (text: string | HTMLElement) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'success',
		title: 'Success!',
		text: typeof text === 'string' ? text : '',
		html: typeof text === typeof HTMLElement ? text : '',
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		showCloseButton: true,
		background: '#48B16E',
		color: '#fff',
		iconHtml:
			'<i class="fa-solid fa-circle-check" style="color: #ffffff;"></i>',
		closeButtonHtml:
			'<i class="fa-solid fa-xmark fa-2xs" style="color: #ffffff;"></i>',
		timer: 3000,
		padding: '0.5rem 0.5rem 1.5rem 1rem',
		width: 'w-full',
		customClass: {
			icon: '!border-none !mt-5 !-mr-1.5',
			title: '!text-lg !mx-3.5 !-mb-1.5 !mt-6',
			closeButton: '!self-start',
			htmlContainer: '!text-sm',
		},
	});
};

export const showFailedAlert = (text?: string | HTMLElement) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'error',
		title: 'Error',
		text:
			typeof text === 'string'
				? text
				: 'An error has occurred, Please try again',
		html: typeof text === typeof HTMLElement ? text : '',
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		showCloseButton: true,
		background: '#f47878',
		color: '#fff',
		iconHtml:
			'<i class="fa-sharp fa-solid fa-circle-xmark" style="color: #f9fafa;"></i>',
		closeButtonHtml:
			'<i class="fa-solid fa-xmark fa-2xs" style="color: #ffffff;"></i>',
		timer: 3000,
		padding: '0.5rem 0.5rem 1.5rem 1rem',
		width: 'w-full',
		customClass: {
			icon: '!border-none !mt-5 !-mr-1.5',
			title: '!text-lg !mx-3.5 !-mb-1.5 !mt-6',
			closeButton: '!self-start',
			htmlContainer: '!text-sm',
		},
	});
};
