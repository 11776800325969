import { amountAtom, purposeAtom } from '../../../store/orderSummaryAtom';
import { useAtomValue } from 'jotai';
import { AmountFormatter, classNames } from '../../../utility/common';
import { IMAGES } from '../../../utility/constant';

const OrderSummary = () => {
	const amountHolder = useAtomValue(amountAtom);
	const purposeHolder = useAtomValue(purposeAtom);

	return (
		<div className='w-full flex justify-center p-2'>
			<div className='w-full flex flex-col text-[#212020] gap-2'>
				<div className='flex justify-center items-center w-full'>
					<img className='w-52 h-28' src={IMAGES.MPH} />
				</div>
				<p className='mb-2 text-lg'>Order Summary</p>
				<hr />
				<div
					className={classNames(
						purposeHolder ? '' : 'mb-4',
						'mt-4 w-full flex flex-row items-center'
					)}
				>
					<div className='flex-1'>Product/Services</div>

					<div>
						{amountHolder ? (
							<AmountFormatter amount={amountHolder} />
						) : (
							'$0.00'
						)}
					</div>
				</div>
				<div
					className={classNames(
						purposeHolder ? '' : 'hidden',
						'mb-4'
					)}
				>
					<p className='break-words'>{purposeHolder}</p>
				</div>
				<hr />
				<div className='mt-4 w-full flex flex-row items-center font-semibold text-base md:text-xl'>
					<div className='flex-1'>Total Amount</div>
					<div className='flex'>
						{amountHolder ? (
							<AmountFormatter amount={amountHolder} />
						) : (
							'$0.00'
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderSummary;
