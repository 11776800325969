import Footer from '../../components/common/Footer';
import { IMAGES } from '../../utility/constant';
import Button from '../../components/common/Button';
import {
	receiptAmountAtom,
	receiptCardBrand,
	receiptcardLastFour,
	receiptemail,
	receiptPaymentDate,
	receiptTransactionNumber,
} from '../../store/receiptAtom';
import { amountAtom, purposeAtom } from '../../store/orderSummaryAtom';
import { useAtom, useAtomValue } from 'jotai';
import { AmountFormatterSuccess, formatDate } from '../../utility/common';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Success = () => {
	const navigate = useNavigate();

	const creditCardBrand = useAtomValue(receiptCardBrand);
	const receiptAmount = useAtomValue(receiptAmountAtom);
	const cardLastFourDigit = useAtomValue(receiptcardLastFour);
	const receiptEmail = useAtomValue(receiptemail);
	const transactionNumber = useAtomValue(receiptTransactionNumber);
	const paymentDate = useAtomValue(receiptPaymentDate);

	const [, setAmountAtom] = useAtom(amountAtom);
	const [, setPurposeAtom] = useAtom(purposeAtom);

	const ccLogo = [
		{
			name: 'visa',
			image: IMAGES.Visa,
		},
		{
			name: 'mastercard',
			image: IMAGES.MasterCard,
		},
		{
			name: 'amex',
			image: IMAGES.Amex,
		},
		{
			name: 'discover',
			image: IMAGES.Discover,
		},
		{
			name: 'diners',
			image: IMAGES.Diners,
		},
		{
			name: 'jcb',
			image: IMAGES.JCB,
		},
		{
			name: 'unionpay',
			image: IMAGES.UnionPay,
		},
	];

	const logoImage = ccLogo.find(
		(card) => card.name === creditCardBrand
	)?.image;

	const handleBack = () => {
		setAmountAtom('');
		setPurposeAtom('');
		navigate('/');
	};

	return (
		<div className='flex flex-col items-between w-full h-screen bg-white'>
			<div className='mt-12 w-full flex flex-col justify-center items-center'>
				<div className='w-[150px] h-[150px] rounded-full bg-[#ccece0] flex justify-center items-center p-8'>
					{/* <img className='w-[100%] h-[100%]' src={IMAGES.Check} /> */}
					<FontAwesomeIcon
						className='w-[100%] h-[100%]'
						icon={faCircleCheck}
						style={{ color: '#55bf97' }}
					/>
				</div>
				<div className='mt-4 flex flex-col justify-center items-center'>
					<p className='font-bold text-[#221F1F] text-2xl'>
						Payment Successful
					</p>
					<p className='text-[#707070] text-center'>
						The payment confirmation has been sent to {receiptEmail}
					</p>
				</div>
				<div className='mt-7 w-full h-full bg-[#F8F8F8] flex flex-col justify-center items-center pb-28'>
					<div className='mt-4 flex w-[30%] max-md:w-[70%] max-sm:w-[90%]'>
						<p className='flex-1 text-[#221F1F]'>Transaction #</p>
						<p className='text-[#221F1F]'>Payment Date</p>
					</div>
					<div className='flex w-[30%] max-md:w-[70%] max-sm:w-[90%]'>
						<p className='flex-1 text-[#221F1F]'>
							{transactionNumber}
						</p>
						<p className='text-[#221F1F]'>
							{formatDate(paymentDate)}
						</p>
					</div>
					<div className='mt-8 flex w-[30%] max-md:w-[70%] max-sm:w-[90%]'>
						<p className='flex-1 text-[#221F1F]'>Payment Method</p>
					</div>
					<div className='flex w-[30%] max-md:w-[70%] max-sm:w-[90%]'>
						<img className='w-12 h-10' src={logoImage} />
						<p className='ml-2 mt-2 flex-1 text-[#221F1F]'>
							{' '}
							**** **** **** {cardLastFourDigit}
						</p>
					</div>
					<hr className='mt-8 w-[30%] max-md:w-[70%] border-2 border-dashed border-[#D6D3D3] cursor-pointer max-sm:w-[90%]' />
					<div className='mt-4 flex font-semibold text-lg w-[30%] max-md:w-[70%] max-sm:w-[90%]'>
						<p className='flex-1 text-[#221F1F]'>
							Total Amount
						</p>
						<p className='text-[#221F1F]'>
							<AmountFormatterSuccess
								amount={parseFloat(receiptAmount)}
							/>
						</p>
					</div>
					<hr className='mt-4 w-[30%] border-2 border-dashed border-[#D6D3D3] cursor-pointer max-md:w-[70%] max-sm:w-[90%]' />
					<div className='w-[30%] mt-12 max-md:w-[70%] max-sm:w-[90%]'>
						<Button
							type='button'
							onClick={handleBack}
							className='w-full p-2 pl-4 pr-4 rounded-sm'
							variant='green'
						>
							Back to Home Page
						</Button>
					</div>
				</div>
			</div>
			<div className='mt-auto bottom-0 z-10'>
				<Footer />
			</div>
		</div>
	);
};

export default Success;
