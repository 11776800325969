import { useEffect, useState } from "react"
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TCheckoutResponse, TErrorResponse } from "../../services/CheckOut/schema";
import { AxiosError } from "axios";
import { confirmPayment } from "../../services/CheckOut";
import { useAtom } from "jotai";
import { receiptAmountAtom, receiptCardBrand, receiptPaymentDate, receiptTransactionNumber, receiptcardLastFour, receiptemail } from "../../store/receiptAtom";
import { showFailedAlert, showSuccessAlert } from "../../utility/alerts";

export const Callback = () => {
	const [params] = useSearchParams();
	const parsedPaymentIntent = params.get('payment_intent');

	const [, setIsProcess] = useState<boolean>(false);
	const navigate = useNavigate();

	const [, setReceiptAmount] = useAtom(receiptAmountAtom);
	const [, setReceiptCardBrand] = useAtom(receiptCardBrand);
	const [, setReceiptLastFour] = useAtom(receiptcardLastFour);
	const [, setReceiptEmail] = useAtom(receiptemail);
	const [, setReceiptPaymentDate] = useAtom(receiptPaymentDate);
	const [, setReceiptTransactionNumber] = useAtom(receiptTransactionNumber);

	const { mutate: confirmMU } = useMutation<
		TCheckoutResponse,
		AxiosError,
		string
	>((data) => confirmPayment(data), {
		onSuccess: (data) => {
			setReceiptAmount(data.amount);
			setReceiptCardBrand(data.cardBrand);
			setReceiptLastFour(data.cardLastFour);
			setReceiptEmail(data.email);
			setReceiptPaymentDate(data.paymentDate);
			setReceiptTransactionNumber(data.transactionNumber);
			setIsProcess(false);
			showSuccessAlert('Your payment was successful!');
			navigate('/success');
		},
		onError: (error) => {
			const err: TErrorResponse = error.response?.data as TErrorResponse;

			showFailedAlert(err.message);
			setIsProcess(false);
			navigate('/');
		},
	}); 

	useEffect(() => {
		if (parsedPaymentIntent) confirmMU(parsedPaymentIntent)
	}, [parsedPaymentIntent])

	return (
		<div className='flex flex-col justify-center items-center h-screen'>
			<div className='animate-spin rounded-full border-t-4 border-blue-500 border-opacity-75 h-16 w-16' />
			
			<div>
				<p className="mt-4 text-gray-600 text-xl">Processing your payment. Please wait a moment.</p>
			</div>
		</div>
	)
}