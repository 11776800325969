import z from 'zod';

export const errorResponse = z.object({
	code: 		z.number(),
	message: 	z.string(),
})

// const amountRegex = z
//   .string()
//   .nonempty({ message: 'Required' })
//   .refine(value => value.length <= 8, {
//     message: 'Maximum of 8 digits',
//   })
//   .refine(value => /^[\d,.\-]+$/.test(value), {
//     message: 'Only numbers are allowed',
//   });

  const nameRegex = z
	.string()
	.regex(/^[a-zA-Z0-9\s\-.’']*$/, { message: 'Input contains invalid characters. Please use only letters, numbers, spaces, hyphens, periods, and apostrophes.' })
	// .regex(/^[a-zA-Z\s]*$/, { message: 'Input must be letters only' })
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'Required' })
	.transform((val) => val.trim().replace(/\s+/g, ' '));

  const purposeRegex = z
	.string()
	.regex(/^(?!\s*$)(\s*\S+\s*)+$/, { message: 'Required' })
	.transform((val) => val.trim().replace(/\s+/g, ' '));

export const checkOutSchema = z.object({
    amount:          		z.number().positive({ message: 'Required' }).min(0.5, 'Minimum amount is 0.5').max(999999.99, 'Maximum amount is 999999.99'),
    name:            		nameRegex,
    email:           		z.string().email(),
    purpose:         		purposeRegex,
    paymentMethodId: 		z.string().optional(),
	  cardNumberComplete: 	z.number().positive({ message: 'Required' }),
	  cardExpiryComplete: 	z.number().positive({ message: 'Required' }),
	  cardCvcComplete: 		z.number().positive({ message: 'Required' }),
});

export const checkOutResponse = z.object({
    transactionNumber:   z.string(),
    paymentDate:         z.string(),
    cardBrand:           z.string(),
    email:               z.string(),
    cardLastFour:        z.string(),
    amount:              z.string(),
	authUrl:		 z.string().optional(),
});

export type TErrorResponse =  z.infer<typeof errorResponse>;
export type TCheckout =  z.infer<typeof checkOutSchema>;
export type TCheckoutResponse =  z.infer<typeof checkOutResponse>;