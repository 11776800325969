import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckOutContent from "./CheckOutContent";

const Payment = () => {
	const stripePublishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
	const getStripePromise = stripePublishableKey;
	const stripePromise = loadStripe(getStripePromise);
    
	return(
        <div className='w-full h-full bg-white'>
            <Elements stripe={stripePromise}>
                <CheckOutContent />
            </Elements>
        </div>
    )
};

export default Payment;
