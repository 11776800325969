import React from 'react';

const Footer: React.FC = () => {
 
  return (
    <div className='w-full'>
        <div className='flex w-full h-[7vh] bg-[#343333] pl-24 pr-24 max-md:pr-12 max-md:pl-12 max-sm:pr-12 max-sm:pl-12 max-sm:flex-col max-sm:h-[15vh] max-sm:justify-center max-sm:items-center'>
            <div className='text-sm flex-1 flex justify-start items-center text-[#D9D7D7] max-md:text-center max-sm:flex max-sm:justify-center max-sm:items-center max-sm:text-center'>
                <p>@Copyright 2014-2024 MPH International</p>
            </div>
        </div>
    </div>
  );
};

export default Footer;
