import React from 'react';
import { classNames } from '../../../../utility/common';
import RequiredField from '../../../common/RequiredField';

interface IProps {
	type: string;
	name?: string;
	disabled?: boolean;
	value?: any;
	onChange?: (value: string) => void;
	placeholder?: string;
	className?: string;
	error?: string;
	label?: string;
	label2?: string;
	label3?: string;
	errorEdit?: string;
	maxLength?: number;
	defaultValue?: string;
	required?: boolean;
}

const FormInputWithCurrency = (props: IProps) => {
	const [value, setValue] = React.useState(props.value || '');

	const onKeyPress = (
		event:
			| React.KeyboardEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLTextAreaElement>
	) => {
		if (props.maxLength !== undefined) {
			const allowedKeys = [
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'.',
				'Backspace',
				'Enter',
				'Tab',
				'ArrowLeft',
				'ArrowRight',
				'ArrowUp',
				'ArrowDown',
			];

			if (
				!allowedKeys.includes(event.key) &&
				!(event.ctrlKey && event.key === 'c') &&
				!(event.ctrlKey && event.key === 'v') &&
				!(event.ctrlKey && event.key === 'x') &&
				!(event.ctrlKey && event.key === 'a')
			) {
				event.preventDefault();
			}

			if (
				event.key === '.' &&
				(event.target as HTMLInputElement).value.includes('.')
			) {
				event.preventDefault();
			}

			const hasDecimal = (
				event.target as HTMLInputElement
			).value.includes('.');
			const selectionStart = (event.target as HTMLInputElement)
				.selectionStart;

			if (
				hasDecimal &&
				selectionStart !== null &&
				selectionStart >=
					(event.target as HTMLInputElement).value.indexOf('.') + 3 &&
				event.key !== 'Backspace' &&
				event.key !== 'Enter' &&
				event.key !== 'Tab' &&
				!(event.ctrlKey && event.key === 'c') &&
				!(event.ctrlKey && event.key === 'v') &&
				!(event.ctrlKey && event.key === 'x') &&
				!(event.ctrlKey && event.key === 'a')
			) {
				event.preventDefault();
			}
		}
	};

	const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		const pastedText = e.clipboardData.getData('text');
		const parsedValue = parseFloat(pastedText);

		if (isNaN(parsedValue)) {
			e.preventDefault();
		}
	};

	const handleOnInput = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (props.maxLength !== undefined) {
			const allowedKeys = [
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'.',
				'Backspace',
				'Enter',
				'Tab',
				'ArrowLeft',
				'ArrowRight',
				'ArrowUp',
				'ArrowDown',
			];

			// Handle keyboard input validation
			if (event.nativeEvent instanceof KeyboardEvent) {
				if (
					!allowedKeys.includes(event.nativeEvent.key) &&
					!(
						event.nativeEvent.ctrlKey &&
						['c', 'v', 'x', 'a'].includes(event.nativeEvent.key)
					)
				) {
					event.preventDefault();
				}

				if (
					event.nativeEvent.key === '.' &&
					event.currentTarget.value.includes('.')
				) {
					event.preventDefault();
				}

				const hasDecimal = event.currentTarget.value.includes('.');
				const selectionStart = event.currentTarget.selectionStart;

				if (
					hasDecimal &&
					selectionStart !== null &&
					selectionStart >=
						event.currentTarget.value.indexOf('.') + 3 &&
					event.nativeEvent.key !== 'Backspace' &&
					event.nativeEvent.key !== 'Enter' &&
					event.nativeEvent.key !== 'Tab' &&
					!(
						event.nativeEvent.ctrlKey &&
						['c', 'v', 'x', 'a'].includes(event.nativeEvent.key)
					)
				) {
					event.preventDefault();
				}
			}

			// Handle paste event validation
			if (event.nativeEvent instanceof ClipboardEvent) {
				const pastedText = event.nativeEvent.clipboardData
					? event.nativeEvent.clipboardData.getData('text')
					: '';

				const parsedValue = parseFloat(pastedText);

				if (isNaN(parsedValue)) {
					event.preventDefault();
				}
			}
		}
	};

	const handleChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		if (props.maxLength !== undefined) {
			const currentValue = event.target.value;
			if (currentValue.length > props.maxLength) {
				setValue(value);
			} else {
				setValue(currentValue);
			}
		} else {
			setValue(event.target.value);
		}
		props.onChange && props.onChange(event.target.value);
	};

	React.useEffect(() => {
		setValue(props.value || '');
	}, [props.value]);

	React.useEffect(() => {
		if (value === '' && props.defaultValue) {
			setValue(props.defaultValue);
		}
	}, []);

	return (
		<>
			<label className='text-[#363636] text-xs font-semibold'>
				{props.label2}
				{props.label2 && props.required && <RequiredField />}
			</label>
			<label className='text-[#707070] text-xs font-regular'>
				{props.label3}
				{props.label3 && props.required && <RequiredField />}
			</label>
			<label className='text-[#262626] text-[11px] font-semibold pb-1'>
				{props.label}
				{props.label && props.required && <RequiredField />}
			</label>
			<div
				className={`relative shadow-none ${
					props.label3 ? 'mt-1' : 'none'
				}`}
			>
				{props.type === 'text-area' ? (
					<textarea
						disabled={props.disabled}
						name={props.name}
						value={value}
						maxLength={props.maxLength}
						placeholder={props.placeholder}
						rows={4}
						onChange={handleChange}
						onKeyDown={onKeyPress}
						className={classNames(
							props.className ? props.className : '',
							props.disabled ? 'bg-gray-200' : '',
							props.error || props.errorEdit
								? 'border-[#DC3545]'
								: 'border-[#D6D3D3] bg-white',
							'text-black w-full text-sm rounded-md border py-2.5 shadow-none',
							'pl-2 focus:ring-0 focus:border-primary outline-none resize-none'
						)}
					/>
				) : (
					<div
						className={classNames(
							props.className ? props.className : '',
							props.error || props.errorEdit
								? 'border-[#DC3545]'
								: 'border-[#D6D3D3]',
							'flex border text-black w-full text-sm rounded-md shadow-none',
							'focus:ring-0 focus:border-primary outline-none'
						)}
					>
						<span className='md:w-[60%] lg:w-[35%] xl:w-[35%] 2xl:w-[35%] min-sm:w-[55%] max-sm:w-[55%] w-[35%] border-r rounded-tl-md rounded-bl-md bg-[#F8F8F8] border-[#D6D3D3] text-[#707070] text-center flex justify-center items-center min-sm:text-[12px] max-sm:text-[12px] px-2'>
							USD
						</span>
						<input
							disabled={props.disabled}
							type={props.type}
							inputMode='decimal'
							name={props.name}
							value={value}
							maxLength={props.maxLength}
							placeholder={props.placeholder}
							onChange={handleChange}
							onInput={handleOnInput}
							onKeyDown={onKeyPress}
							onPaste={handleOnPaste}
							className={classNames(
								props.className ? props.className : '',
								props.disabled ? 'bg-gray-200' : '',
								props.error || props.errorEdit
									? 'border-[#DC3545]'
									: 'border-[#D6D3D3] bg-white',
								'text-right text-black w-full text-sm rounded-md p-2.5 shadow-none',
								'pl-2 focus:ring-0 focus:border-primary outline-none'
							)}
						/>
					</div>
				)}
			</div>
			{props.error && (
				<p className='text-[#DC3545] text-xs pl-1 py-1'>
					{props.error}
				</p>
			)}
			{props.errorEdit && (
				<p className='absolute text-[#DC3545] text-xs pl-1 py-1'>
					{props.errorEdit}
				</p>
			)}
		</>
	);
};

export default FormInputWithCurrency;
