import { axiosInstance } from "../axios";
import { TCheckout, checkOutSchema } from "./schema";

export const addCheckOut = async (data: TCheckout) => {
	const validateCheckout = checkOutSchema.parse(data);
	const response = await axiosInstance.post('/api/v1/payments', validateCheckout);
	return response.data;
};

export const confirmPayment = async (id: string) => {
	const response = await axiosInstance.post(`/api/v1/payments/${id}/confirm`);
	return response.data;
};