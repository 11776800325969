import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
} from '@stripe/react-stripe-js';
import {
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import FormInput from '../Input';
import { Control, Controller } from 'react-hook-form';
import { TCheckout } from '../../../services/CheckOut/schema';
import { useAtom } from 'jotai';
import { nameAtom } from '../../../store/orderSummaryAtom';
import { useEffect } from 'react';
export interface ICardElement {
	error: boolean;
	errorMessage?: string;
	complete?: boolean;
}
export interface InputCardInfoFormProps {
	control: Control<TCheckout, any>;
	cardNumberState: ICardElement;
	cardExpiryState: ICardElement;
	cardCvcState: ICardElement;
	setCardNumberState: (value: ICardElement) => void;
	setCardExpiryState: (value: ICardElement) => void;
	setCardCvcState: (value: ICardElement) => void;
	setCardBrand: (value: string) => void;
}

const InputCardInfoForm = (props: InputCardInfoFormProps) => {
	const [fullName, setName] = useAtom(nameAtom);

	const onChangeCardNumber = (event: StripeCardNumberElementChangeEvent) => {
		props.setCardBrand(event?.brand);

		if (event.error) {
			props.setCardNumberState({
				error: true,
				errorMessage: event.error.code,
				complete: false,
			});
		} else {
			props.setCardNumberState({ error: false });
			if (event.complete) {
				props.setCardNumberState({ error: false, complete: true });
			}
		}
	};

	const onChangeCardExpiry = (event: StripeCardExpiryElementChangeEvent) => {
		if (event.error) {
			props.setCardExpiryState({
				error: true,
				errorMessage: event.error.code,
				complete: false,
			});
		} else {
			props.setCardExpiryState({ error: false });
			if (event.complete) {
				props.setCardExpiryState({ error: false, complete: true });
			}
		}
	};

	const onChangeCardCVC = (event: StripeCardCvcElementChangeEvent) => {
		if (event.error) {
			props.setCardCvcState({
				error: true,
				errorMessage: event.error.code,
				complete: false,
			});
		} else {
			props.setCardCvcState({ error: false });
			if (event.complete) {
				props.setCardCvcState({ error: false, complete: true });
			}
		}
	};

	useEffect(() => {
		setName('');
	}, []);

	window.addEventListener("pageshow", () => {
		setName('');
	});

	return (
		<div className='flex flex-col w-full p-6 text-sm rounded'>
			<div>
				<p className='text-[#707070] text-lg font-semibold'>
					Debit/Credit Card
				</p>
				<div>
					<Controller
						control={props.control}
						name='name'
						render={({
							field: { name, onChange },
							fieldState: { error },
						}) => (
							<FormInput
								onChange={(newValue) => {
									setName(newValue);
									onChange(newValue);
								}}
								name={name}
								error={error?.message}
								type='text'
								label3='Full Name'
								maxLength={50}
								value={fullName}
							/>
						)}
					/>
				</div>
			</div>
			<div className='w-full mt-2'>
				<label className='text-[#707070] text-xs font-regular'>
					Card Number
				</label>

				<div className='relative mt-2'>
					<div>
						<Controller
							control={props.control}
							name='cardNumberComplete'
							render={({
								field: { name },
								fieldState: { error },
							}) => (
								<FormInput
									name={name}
									error={error?.message}
									type='text'
									tabIndex={true}
								/>
							)}
						/>
					</div>

					<div className='absolute w-full top-0'>
						<CardNumberElement
							id='card-element'
							className={'py-3 px-3 w-full rounded-md bg-none'}
							options={{
								showIcon: false,
								iconStyle: 'solid',
								placeholder: '0000 0000 0000 0000',
							}}
							onChange={onChangeCardNumber}
						/>
					</div>
				</div>

				<p className='mb-2 ml-1 text-xs text-[#DC3545]'>
					{props.cardNumberState.errorMessage === 'required'
						? 'Required'
						: ''}
					{props.cardNumberState.errorMessage === 'invalid_number'
						? 'Invalid card number'
						: ''}
				</p>
			</div>

			<div className='flex flex-row w-full gap-4'>
				<div className='w-[55%]'>
					<label className='text-[#707070] text-xs font-regular'>
						Card Expiry
					</label>

					<div className='relative mt-2'>
						<div>
							<Controller
								control={props.control}
								name='cardExpiryComplete'
								render={({
									field: { name },
									fieldState: { error },
								}) => (
									<FormInput
										name={name}
										error={error?.message}
										type='text'
										tabIndex={true}
									/>
								)}
							/>
						</div>

						<div className='absolute w-full top-0'>
							<CardExpiryElement
								id='card-expiry-element'
								className={
									'py-3 px-3 w-full rounded-md bg-none'
								}
								onChange={onChangeCardExpiry}
							/>
						</div>
					</div>

					<p className='mb-2 ml-1 text-xs text-[#DC3545]'>
						{props.cardExpiryState.errorMessage === 'required'
							? 'Required'
							: ''}
						{props.cardExpiryState.errorMessage ===
						'invalid_expiry_year_past'
							? 'Invalid expiry year'
							: ''}
					</p>
				</div>

				<div className='w-[45%]'>
					<label className='text-[#707070] text-xs font-regular'>
						CVV/CVC
					</label>

					<div className='relative mt-2'>
						<div>
							<Controller
								control={props.control}
								name='cardCvcComplete'
								render={({
									field: { name },
									fieldState: { error },
								}) => (
									<FormInput
										name={name}
										error={error?.message}
										type='text'
										tabIndex={true}
									/>
								)}
							/>
						</div>

						<div className='absolute w-full top-0'>
							<CardCvcElement
								id='card-expiry-element'
								className={
									'py-3 px-3 w-full rounded-md bg-none'
								}
								options={{
									placeholder: 'CVV / CVC',
								}}
								onChange={onChangeCardCVC}
							/>
						</div>
					</div>

					<p className='mb-2 ml-1 text-xs text-[#DC3545]'>
						{props.cardCvcState.errorMessage === 'required'
							? 'Required'
							: ''}
						{props.cardCvcState.errorMessage === 'incomplete_cvc'
							? 'Invalid CVV/CVC'
							: ''}
					</p>
				</div>
			</div>
		</div>
	);
};

export default InputCardInfoForm;
