import FormInput from '../../../components/Forms/Input';
import FormInputWithCurrency from '../../../components/Forms/Input/WithCurrency';
import { useAtom } from 'jotai';
import { amountAtom, purposeAtom } from '../../../store/orderSummaryAtom';
import { Control, Controller } from 'react-hook-form';
import { TCheckout } from '../../../services/CheckOut/schema';
import { useEffect } from 'react';
interface AmountProps {
	control: Control<TCheckout, any>;
}

const Amount = (props: AmountProps) => {
	const [amount, setAmountAtomHolder] = useAtom(amountAtom);
	const [purpose, setPurposeAtom] = useAtom(purposeAtom);

	useEffect(() => {
		setAmountAtomHolder('');
		setPurposeAtom('');
	}, []);

	window.addEventListener("pageshow", () => {
		setAmountAtomHolder('');
		setPurposeAtom('');
	});

	return (
		<div className='flex justify-center items-center w-full gap-2 p-4 pl-6 pr-6'>
			<div className='w-[45%]'>
				<Controller
					control={props.control}
					name='amount'
					render={({
						field: { name, onChange },
						fieldState: { error },
					}) => (
						<FormInputWithCurrency
							onChange={(newValue) => {
								const numericValue = parseFloat(
									newValue.replace(/[^0-9\.]/g, '')
								);

								const sanitizedNumericValue = isNaN(
									numericValue
								)
									? 0
									: numericValue;

								const formattedValue = isNaN(
									sanitizedNumericValue
								)
									? '0'
									: sanitizedNumericValue.toLocaleString();

								setAmountAtomHolder(formattedValue);
								onChange(sanitizedNumericValue);
							}}
							name={name}
							error={error?.message}
							type='text'
							label3='Enter Amount'
							maxLength={10}
							value={amount}
							className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
						/>
					)}
				/>
			</div>
			<div className='w-[55%]'>
				<Controller
					control={props.control}
					name='purpose'
					render={({
						field: { name, onChange },
						fieldState: { error },
					}) => (
						<FormInput
							onChange={(newValue) => {
								setPurposeAtom(newValue);
								onChange(newValue);
							}}
							name={name}
							error={error?.message}
							type='text'
							label3='Product/Services'
							maxLength={50}
							value={purpose}
						/>
					)}
				/>
			</div>
		</div>
	);
};

export default Amount;
