import { RouteObject } from 'react-router-dom';
import Payment from '../../pages/Payment';
import Success from '../../pages/Success';
import { Callback } from '../../pages/Callback';

export const PublicRoutes: RouteObject = {
	children: [
		{
			path: '/',
			element: <Payment />,
		},
		{
			path: '/success',
			element: <Success />,
		},
		{
			path: '/callback',
			element: <Callback />,
		},
	]
};
