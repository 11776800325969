import React from 'react';
import { classNames } from '../../../utility/common';
import RequiredField from '../../common/RequiredField';

interface IProps {
	type: string;
	name?: string;
	disabled?: boolean;
	value?: any;
	onChange?: (value: string) => void;
	placeholder?: string;
	className?: string;
	error?: string;
	label?: string;
	label2?: string;
	label3?: string;
	errorEdit?: string;
	maxLength?: number;
	defaultValue?: string;
	required?: boolean;
	tabIndex?: boolean
}

const FormInput = (props: IProps) => {
	const [value, setValue] = React.useState(props.value || '');

	// const onKeyPress = (
	// 	event:
	// 		| React.KeyboardEvent<HTMLInputElement>
	// 		| React.KeyboardEvent<HTMLTextAreaElement>
	// ) => {
	// 	if (props.maxLength !== undefined) {
	// 		if (
	// 			event.key === '+' ||
	// 			event.key === 'e' ||
	// 			event.key === 'E' ||
	// 			event.key === '.'
	// 		) {
	// 			event.preventDefault();
	// 		}
	// 	}
	// };

	const handleChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		if (props.maxLength !== undefined) {
			const currentValue = event.target.value;
			if (currentValue.length > props.maxLength) {
				setValue(value);
			} else {
				setValue(currentValue);
			}
		} else {
			setValue(event.target.value);
		}
		props.onChange && props.onChange(event.target.value);
	};

	React.useEffect(() => {
		setValue(props.value || '');
	}, [props.value]);

	React.useEffect(() => {
		if (value === '' && props.defaultValue) {
			setValue(props.defaultValue);
		}
	}, []);

	return (
		<>
			<label className='text-[#363636] text-xs font-semibold'>
				{props.label2}
				{props.label2 && props.required && <RequiredField />}
			</label>
			<label className='text-[#707070] text-xs font-regular'>
				{props.label3}
				{props.label3 && props.required && <RequiredField />}
			</label>
			<label className='text-[#262626] text-[11px] font-semibold pb-1'>
				{props.label}
				{props.label && props.required && <RequiredField />}
			</label>
			<div
				className={`relative shadow-none ${
					props.label3 ? 'mt-1' : 'none'
				}`}
			>
				{props.type === 'text-area' ? (
					<textarea
						disabled={props.disabled}
						name={props.name}
						value={value}
						maxLength={props.maxLength}
						placeholder={props.placeholder}
						rows={4}
						onChange={handleChange}
						// onKeyDown={onKeyPress}
						className={classNames(
							props.className ? props.className : '',
							props.disabled ? 'bg-gray-200' : '',
							props.error || props.errorEdit
								? 'border-[#DC3545]'
								: 'border-[#D6D3D3] bg-white',
							'text-black w-full text-sm rounded-md border py-2.5 shadow-none',
							'pl-2 focus:ring-0 focus:border-primary outline-none resize-none'
						)}
					/>
				) : (
					<input
						tabIndex={props.tabIndex ? -1 : 0}
						disabled={props.disabled}
						type={props.type}
						name={props.name}
						value={value}
						maxLength={props.maxLength}
						placeholder={props.placeholder}
						onChange={handleChange}
						// onKeyDown={onKeyPress}
						className={classNames(
							props.className ? props.className : '',
							props.disabled ? 'bg-gray-200' : '',
							props.error || props.errorEdit
								? 'border-[#DC3545]'
								: 'border-[#D6D3D3] bg-white',
							'text-black w-full text-sm rounded-md border py-2.5 shadow-none',
							'pl-2 focus:ring-0 focus:border-primary outline-none'
						)}
					/>
				)}
			</div>
			{props.error && (
				<p className='text-[#DC3545] text-xs pl-1 py-1'>
					{props.error}
				</p>
			)}
			{props.errorEdit && (
				<p className='absolute text-[#DC3545] text-xs pl-1 py-1'>
					{props.errorEdit}
				</p>
			)}
		</>
	);
};

export default FormInput;
