import React, { forwardRef, ForwardedRef } from 'react';
import { classNames } from '../../../utility/common';

interface ButtonProps {
	children: React.ReactNode;
	onClick?: (e: any) => void;
	onSubmit?: (e: any) => void;
	variant?: 'primary' | 'secondary' | 'danger' | 'orange' | 'green';
	className?: string;
	disabled?: boolean;
	type?: 'button' | 'submit';
	form?: string | undefined;
	selected?: boolean;
}
const isSelected = (selected?: boolean) => {
	return selected === true
		? 'bg-primary text-white'
		: selected === false
		? 'text-black bg-transparent'
		: '';
};

const Button = forwardRef(function Button(
	{
		onClick,
		onSubmit,
		children,
		type,
		form,
		className,
		variant,
		disabled,
		selected = false || undefined,
	}: ButtonProps,
	ref: ForwardedRef<HTMLButtonElement>
) {
	const primaryClass =
		'bg-primary hover:bg-[#BA5B23] border-transparent disabled:bg-[#E0781D] text-white';
	const secondaryClass =
		'bg-white hover:bg-[#BA5B23] border-primary disabled:bg-white';
	const dangerClass =
		'bg-custom-red-500 hover:bg-custom-red-600 border-transparent disabled:bg-custom-red-500 text-white';
	const orangeClass =
		'bg-[#E0781D] hover:bg-[#E0781D] border-transparent disabled:bg-[#E0781D] text-white';
	const greenClass =
		'bg-[#236081] hover:bg-[#2596be] border-transparent disabled:bg-[#BED3C6] text-white';
	return (
		<button
			ref={ref}
			type={type}
			form={form}
			onClick={onClick}
			onSubmit={onSubmit}
			className={classNames(
				className ? className : '',
				isSelected(selected),
				variant === 'primary' ? primaryClass : '',
				variant === 'secondary' ? secondaryClass : '',
				variant === 'danger' ? dangerClass : '',
				variant === 'orange' ? orangeClass : '',
				variant === 'green' ? greenClass : '',
				'border shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'
			)}
			disabled={disabled}
		>
			{children}
		</button>
	);
});

Button.displayName = 'Button';

export default Button;
