import FormInput from '../../../components/Forms/Input';
import { Control, Controller } from 'react-hook-form';
import { TCheckout } from '../../../services/CheckOut/schema';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { emailAtom } from '../../../store/orderSummaryAtom';
interface EmailProps {
	control: Control<TCheckout, any>;
}

const Email = (props: EmailProps) => {
	const [email, setEmail] = useAtom(emailAtom);

	useEffect(() => {
		setEmail('');
	}, []);

	window.addEventListener("pageshow", () => {
		setEmail('');
	});

	return (
		<div className='flex justify-center items-center w-full p-2 pl-6 pr-6'>
			<div className='w-full'>
				<Controller
					control={props.control}
					name='email'
					render={({
						field: { name, onChange },
						fieldState: { error },
					}) => (
						<FormInput
							onChange={(newValue) => {
								setEmail(newValue);
								onChange(newValue);
							}}
							name={name}
							error={error?.message}
							type='text'
							label3='Email Address*'
							maxLength={50}
							value={email}
						/>
					)}
				/>
				<p className='mt-2 text-[11px] text-[#707070]'>
					You’ll receive a confirmation email with the order number.
				</p>
			</div>
		</div>
	);
};

export default Email;
